@import '../../stylevars';

.condensed-review {
        .flex-container {
            display: grid;
            gap: 0px;
        }

        .subsection {
            grid-column: 1 / span 2;
        }

        .section-header {
            color: $colorGreen;
            font-size: $fontSizeXxlarge;
            font-weight: 500;
            margin-bottom: 8px;
            margin-top: 8px;
            grid-column: 1 / span 2;
        }

        .flex-label {
            font-weight: 600;
            font-size: 14px;
            color: #212121;
            grid-column: 1;
        }

        .flex-value {
            border: none;
            padding: none;
            color: $colorSecondaryText;
            font-size: 14px;
            font-weight: 400;
            grid-column: 2;
            margin-bottom: 8px;
            min-width: 200px;
        }

        .flex-half {
             display: grid;
             grid-template-columns: max-content auto;
             break-inside: avoid;
             page-break-inside: avoid;
             column-gap: 10px;
             max-width: max(30vw, 700px);
             align-content: flex-start;
        }

        .flex-half:only-child {
            grid-template-columns: max-content minmax(min-content, 550px);
        }

        @media (min-width: 740px) {
            .completed-page.flex-container {
                grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
                gap: 60px;
            }
        
            .completed-page.flex-section {
                grid-template-columns: max-content min(400px, calc(50vw - 220px));
            }
        
        }

        @media print {
            .flex-half {
                break-inside: auto;
                page-break-inside: auto;
            }
        }

    
}