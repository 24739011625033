@import '../../stylevars';

.link {
    font-size: $fontSizeLarge;
    color: #0A79CE;
    text-decoration: none;
    &:visited {
      color: #0A79CE;
    }
    &:hover, &:active {
      color: #0A79CE;
      text-decoration: none;
    }
  }
  .left-align {
    display: flex;
    justify-content: flex-start;
  }
  .divider {
    border-top: 1px solid $colorLightGray
  }