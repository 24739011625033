$inputWidth: 380px;

.user-selection {
  .selection-input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    
    input {
      width: $inputWidth;
    }
    button {
      margin-top: 8px;
    }
  }
  .selection-found-card {
    border-radius: 4px;
    border: none;
    background-color: rgba(11,120,208,0.1);  
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
    padding: 20px 20px 20px 15px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: calc($inputWidth - 15px);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    text-align: left;
    .card-radio-symbol {
      height: 25px;
      width: 25px;
      flex-shrink: 0;
      border: 3px solid gray;
      border-radius: 50%;
      position: relative;
      &.selected {
        &::after {
          content: '';
          position: absolute;
          top: 0; left: 0; right: 0; bottom: 0;
          border-radius: 50%;
          margin: 4px;
          background: gray;
        }
      }
    }
    .card-contents {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &:hover, &:focus {
      background-color: rgba(92, 147, 192, 0.2);  
    }
  }
  ul {
    font-size: 16px;
  }
}