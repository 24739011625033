.toast-container {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  z-index: 100;
  user-select: none;
  pointer-events: none;
  font-family: "Roboto", sans-serif;
  .toast-item {
    padding: 5px 20px;
    border-radius: 4px;
    min-width: 300px;
    text-align: center;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
    p {
      color: white;
    }
    &.interactive {
      user-select: auto;
      pointer-events: auto;
    }
    button {
      background: none;
      color: white;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
      font-weight: 600;
      border-radius: 4px;
      padding: 5px 10px;
      display: inline-block;
      &:hover, &:focus {
        background: rgba(white, 0.1);
      }
    }

    &.in-progress {
      background: rgb(49, 49, 49);
    }
    &.success {
      background: #6FA135;
    }
    &.failed {
      
      background: #B71B1C;
    }
    &.delete {
      background: #B71B1C;
      display: flex;
      flex-direction: row;
      p {
        margin-right: 20px;
      }
      color: #ecc1c1;
    }
  }
}