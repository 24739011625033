body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* The tweaks below probably don't belong here but they are over-writing MUI styles that are problematic */
#root .MuiFormControl-marginDense {
  margin-top: 2px;
  margin-bottom: 2px;
}

#root .MuiFormControlLabel-root {
  vertical-align: initial;
}

#root .MuiCardContent-root:last-child {
  padding-bottom: 16px;
}