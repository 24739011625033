.cancel-dialog {
  div.MuiPaper-root {
    width: 600px;
    max-width: 100%;
  }
  h2 {
    margin-top: 8px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.1px;
  }
  p {
    margin-top: 0;
    margin-bottom: 40px;
  }
  button {
    margin-bottom: 20px;
    margin-right: 25px;
  }
}