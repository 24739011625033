@import '../../stylevars';

  .submit-error-dialog {
    div.MuiPaper-root {
      width: 600px;
      max-width: 100%;
    }
    h2 {
      color: #B71B1C;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.1px;
    }
    p {
      margin-top: 0;
      margin-bottom: 50px;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .submit-upload-warning-dialog {
    div.MuiPaper-root {
      width: 600px;
      max-width: 100%;
    }
    h2 {
      color: #3571A1;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.1px;
    }
    p {
      color: $colorLightGray;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 50px;
    }
    button {
      margin-bottom: 20px;
      margin-right: 16px;
    }
  }

  .submit-validation-dialog {
    div.MuiPaper-root {
      width: 600px;
      max-width: 100%;
    }
    h2 {
      font-size: 24px !important;
      font-weight: 600;
      letter-spacing: -0.1px;
    }
    p {
      margin-top: 0;
      margin-bottom: 40px !important;
    }
    button {
      margin-bottom: 20px;
    }
  }

.review-form {
  .link {
    font-size: $fontSizeLarge;
    color: #0A79CE;
    text-decoration: none;
    &:visited {
      color: '';
    };
    &:hover, &:active {
      color: #0A79CE;
      text-decoration: none;
    }
  }
  .left-align {
    display: flex;
    justify-content: flex-start;
  }
  .divider {
    border-top: 1px solid $colorLightGray;
    width: 850px,
  }
  // Adding these classes inside the table class ensures these properties are higher priority than mui's table classes
  .table {
    .cell {
      border: none;
      padding: none;
      white-space: nowrap;
      color: $colorSecondaryText;
      font-size: 14px;
    }
    
    .left-cell {
      border: none;
      padding: none;
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
      color: #212121;
    }
  }

  .force-max-width {
    width: 178px;
  }

  .MuiTableCell-root {
    border-bottom: none !important;
  }
  .missing-info-text {
      vertical-align: middle;
      color: #B71C1C;
      margin-left: 10px !important;
  }

  .MuiTableCell-sizeSmall {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
